












































import { Component, Prop, Vue } from "vue-property-decorator";
let marked = require("marked");

import TextAboutNL from "@/locales/text-about.nl.md";
import TextAboutEN from "@/locales/text-about.en.md";



@Component
export default class AboutComponent extends Vue {
  getAboutText() {
    var md = this.$i18n.locale == "nl" ? TextAboutNL : TextAboutEN;
    marked.setOptions({ breaks: true });
    return marked(md);
  }


}
